import styled from 'styled-components'
import { space } from 'styled-system'

export const BlogPostCategoriesContainer = styled.div`
  width: 100%;

  .ant-button-hover-override {
    margin-bottom: 8px;
    margin-right: 8px;
    border-color: #1890ff;

    &:hover,
    &:focus {
      border-color: #1890ff;
      color: white;
      background-color: #1890ff;
    }
  }

  .ant-button-hover-override-active {
    margin-bottom: 8px;
    margin-right: 8px;
    border-color: #1890ff;
    color: white;
    background-color: #1890ff;
  }
  ${space}
`

export const BlogPostLoadMoreButtonContainer = styled.div`
  .ant-button-hover-override {
    margin-bottom: 8px;
    background: #edcd36;
    border-color: #edcd36;

    &:hover {
      border-color: #edcd36;
      color: #edcd36;
      background-color: white;
    }
  }
  ${space}
`
