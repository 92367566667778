import React, { useEffect, useState } from 'react'
import { Button, Card, Col, PageHeader, Row } from 'antd'
import { FiChevronsDown } from 'react-icons/fi'

import BlogPostPreviewCard from '../../common/blog/BlogPostPreviewCard'
import { BlogPostCategoriesContainer, BlogPostLoadMoreButtonContainer } from './blogLanding.style'
import BannerBG from '../../../assets/image/banner-texture.png'

const blogPostPreviewCardTitleStyle = {
  color: '#ffffff',
  fontSize: '1.50rem',
  fontWeight: 700,
  lineHeight: 1.2,
  letterSpacing: '-0.025em',
  background: `linear-gradient(45deg, rgba(99, 23, 25, 0.8) 0%, rgba(176, 58, 62, 0.7) 75%), url(${BannerBG})`,
  maxWidth: '100vw',
}

const BlogLandingSection = ({ blogItemNodes }) => {
  const allCategories = ['All']
  blogItemNodes
    .filter(node => node?.frontmatter?.categories)
    .forEach(node => {
      node.frontmatter.categories.forEach(category => {
        if (allCategories.indexOf(category) === -1) {
          allCategories.push(category)
        }
      })
    })
  const [allPosts, setAllPosts] = useState(blogItemNodes)
  const [visiblePosts, setVisiblePosts] = useState([...blogItemNodes.slice(0, 10)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(blogItemNodes.length > 10)
  const [blogCategories, setBlogCategories] = useState(allCategories)
  const [activeCategory, setActiveCategory] = useState('All')

  const showLoadMoreButton = (hasMore, activeCategory) => {
    return hasMore && activeCategory === 'All'
  }

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (activeCategory !== 'All') {
      const applicablePosts = allPosts.filter(post => {
        const categories = post?.frontmatter?.categories
        if (categories && categories.indexOf(activeCategory) !== -1) {
          return post
        }
      })
      setVisiblePosts(applicablePosts)
    } else {
      setVisiblePosts([...allPosts.slice(0, 10)])
    }
  }, [activeCategory])

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = visiblePosts.length
      const isMore = currentLength < allPosts.length
      const nextResults = isMore ? allPosts.slice(currentLength, currentLength + 10) : []
      setVisiblePosts([...visiblePosts, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = visiblePosts.length < allPosts.length
    setHasMore(isMore)
  }, [visiblePosts])

  return (
    <>
      <PageHeader title='Blog Posts' subTitle='All RAVENii blog posts.' style={{ marginTop: 116 }} />
      <Row>
        <BlogPostCategoriesContainer marginBottom={8}>
          <Col xs={{ span: 24 }} sm={{ span: 20, offset: 2 }}>
            <Card title={'Blog Categories'} bordered={true} headStyle={blogPostPreviewCardTitleStyle}>
              {blogCategories.map(category => {
                const className = category === activeCategory ? 'ant-button-hover-override-active' : 'ant-button-hover-override'
                return (
                  <Button
                    key={`${category}-Category`}
                    type='secondary'
                    shape='round'
                    className={className}
                    onClick={e => {
                      e.preventDefault()
                      setActiveCategory(category)
                    }}
                  >
                    {category}
                  </Button>
                )
              })}
            </Card>
          </Col>
        </BlogPostCategoriesContainer>
      </Row>
      {visiblePosts.map(node => {
        return (
          <Row key={`${node.frontmatter.title}-Blog`} style={{ marginBottom: 32 }}>
            <BlogPostPreviewCard markdownNode={node} />
          </Row>
        )
      })}

      {showLoadMoreButton(hasMore, activeCategory) && (
        <Row>
          <Col xs={{ span: 12, offset: 6 }} sm={{ span: 8, offset: 8 }}>
            <BlogPostLoadMoreButtonContainer mt={12} mb={12}>
              <Button
                type='secondary'
                shape='round'
                icon={<FiChevronsDown style={{ marginBottom: '-0.25rem', marginRight: 5 }} />}
                size={'large'}
                className={'ant-button-hover-override'}
                onClick={handleLoadMore}
                block
              >
                Load More Posts
              </Button>
            </BlogPostLoadMoreButtonContainer>
          </Col>
        </Row>
      )}
    </>
  )
}

export default BlogLandingSection
