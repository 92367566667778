import React from 'react'
import { ThemeProvider } from 'styled-components'
import { graphql } from 'gatsby'
import Sticky from 'react-stickynode'

import { raveniiTheme } from '../theme/ravenii'
import { ResetCSS } from '../assets/css/style'
import { GlobalStyle, ContentWrapper } from '../components/layout/container.style'

import { DrawerProvider } from '../components/common/contexts/DrawerContext'
import Seo from '../components/seo'
import Navbar from '../components/layout/Navbar'
import BlogLandingSection from '../components/containers/BlogLanding'
import ContactUsSection from '../components/containers/ContactUs'
import Footer from '../components/layout/Footer'

import 'antd/dist/antd.css'

const IndexPage = ({
  data: {
    allMarkdownRemark: { nodes },
  },
}) => {
  return (
    <ThemeProvider theme={raveniiTheme}>
      <>
        <Seo title='RAVENii | Blog' />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <BlogLandingSection blogItemNodes={nodes} />
          <ContactUsSection />

          <Footer />
        </ContentWrapper>
      </>
    </ThemeProvider>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { fields: { collection: { eq: "blog" } } }) {
      nodes {
        excerpt(pruneLength: 750)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          featured
          tags
          categories
          header {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
